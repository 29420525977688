// Color Pallete 🖌

@import "../../../../styles/Colors.scss";

///////////////////////////////////////////
.product_card {
  // padding: 0px !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  .product-card-inner {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.08);
  }
  .price-cut {
    text-decoration: line-through;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    margin-right: 5px;
    color: #cccccc;
  }
  .product_price {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    span {
      color: $black;
      font-size: 15px;
      font-family: "ZemestroStd-Light" !important;
    }
  }
  .wishlist_icon-main {
    background-color: white;
    border-radius: 100px;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 7px 7px 4px 7px;
    z-index: 1;
  }
  .wishlist_icon {
  }
  .quick-shop_icon-main {
    background-color: var(--background-color);
    height: 29px;
    width: 30px;
    border-radius: 10px 10px 30px 10px;
    position: absolute;
    bottom: 0px;
    right: 10px;
    padding: 3px 0px 0px 5px;
    z-index: 1;
  }
  .ant-card {
    border: 0px !important;
    width: 97%;
    padding-bottom: 5px;

    .ant-card-cover {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 100%;
      margin: 10px auto;

      .outOfStock_container {
        position: absolute;
        z-index: 1;
        // top: 165px;
        // left: 0px;
        width: 100%;

        .outOfStock_tag {
          background-color: $outOfStockTagBg;
          font-size: 16px;
          color: $surface;
          width: 100%;
          display: block;
          font-family: "ZemestroStd-Regular" !important;
          height: 30px;
        }
      }

      .mobile_outOfStock_container {
        .outOfStock_tag {
          background-color: $outOfStockTagMobileBg;
          height: 20px;
        }
      }
    }

    img {
      border-radius: 0px !important;
      height: 100%!important;
    }

    .ant-card-body {
      padding: 10px;
      position: relative;
      background: $white;
      text-align: start;
      margin-left: 0px;
      .ant-card-meta {
        width: calc(100% - 5px);
      }
      .ant-card-meta-title {
        margin: 0px;

        // .product_title {
        //   letter-spacing: 0px;
        //   color: #686868;
        //   opacity: 1;
        //   font-size: 13px;
        // }
        font-size: 14px;

        text-overflow: revert;
        // overflow: revert;
        white-space: revert;
        line-height: 0px;

        overflow: hidden;
        // font-weight: 600;
        // margin-bottom: 5px;

        &.product_title_two_lines {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .product_title {
        display: block;
        font-size: 15px;
        color: $productTitle;
        font-family: "ZemestroStd-Regular" !important;
        color: #000;
        font-weight: 100;
        line-height: 23px;
      }

      .product_title_homepage {
        height: 50px;
      }

      .mobile_product_title {
        display: block;
        height: 37px;
        color: $productTitle;
        line-height: 20px;
      }

      .mobile_product_title_homepage {
        display: block;
        height: 37px;
        color: $productTitle;
        line-height: 20px;
        margin-top: 5px;
      }

      .ant-space {
        gap: 2px !important;

        .product_price_mega_search {
          // opacity: 1;
          // font-size: 12px;
          span {
            color: $black;
            font-size: 8px;
            font-family: "ZemestroStd-Regular" !important;
          }
        }

        .product_price_mobile {
          margin-top: 10px;
          span {
            color: #393838 !important;
            font-size: 13px;
            font-family: "ZemestroStd-Regular" !important;
          }
        }

        del {
          color: $secondaryTwo;
          font-size: 12px;
          // font-weight: 600;
        }

        .discount_tag {
          background-color: $discountTagBg;
          margin: 0px;
          padding: 2px;
          border: 0px;

          span {
            font-size: 12px;
            color: $discountTagColor;
            // font-weight: 600;
          }
        }

        .product_tags_container {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          scrollbar-width: thin;
          scrollbar-color: var(--scrollbar-color)
            var(--scrollbar-background-color);
          padding-bottom: 5px;

          .onlineOnly_tag {
            background-color: $onlineOnlyTagBg;
            color: $secondaryTwo;
            margin: 0px;

            .ant-typography {
              font-size: 11px;
              font-family: "ZemestroStd-Regular" !important;
            }
          }

          .freeShipping_tag {
            background-color: $freeShippingTagBg;
            color: $secondaryTwo;
            margin: 0px;

            .ant-typography {
              font-size: 11px;
              font-family: "ZemestroStd-Regular" !important;
            }
          }
        }
      }

      // .ant-tag {
      //   border-radius: 0px;
      // }

      .mobile_discount_tag {
        // background-color: $discountTagColor !important;
        background-color: #ffeff1 !important;
        position: absolute;
        top: 3px;
        right: -5px;
        border-radius: 100px;

        .ant-typography {
          // color: $white;
          font-size: 10px;
          color: #ea462f;
          // font-weight: 600;
        }
      }
    }
  }
}

// Mz css
.product_tags_container:empty {
  display: none;
}

.product_card > a {
  height: 100%;
  display: flex;
}
.product-card-price-container > div > div {
  flex-direction: row;
}
@media screen and (max-width: 1023px) {
  .wishlist_icon-main {
    right: 5px;
  }
  .product_price {
    margin-top: 10px;
    font-size: 13px;
  }
  .price-cut {
    margin-right: 5px;
    font-size: 10px;
  }
}
